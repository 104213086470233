import ScrollUtils from '@/app_code/ScrollUtils';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/properties/for-sale',
    name: 'Properties For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  {
    path: '/insight-and-tips',
    name: 'Insight and Tips',
    component: () => import('../views/InsightAndTips.vue'),
  },
  {
    path: '/buyers-looking',
    name: 'BuyersLooking',
    component: () => import('../views/BuyersLooking.vue'),
  },
  {
    path: '/properties/sold',
    name: 'Sold Properties',
    component: () => import('../views/PropertiesSold.vue'),
  },
  {
    path: '/survey',
    name: 'Survey',
    meta: {
      hide: true,
      hideAncillaries: true, // Disables the header and footer
    },
    component: () => import('../views/Survey.vue'),
  },
  {
    path: '/forms/buyer-enquiry',
    name: 'Buyer Enquiry',
    component: () => import('../views/BuyerEnquiry.vue'),
  },
  {
    path: '/forms/whats-my-home-worth',
    name: 'Whats My Home Worth?',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/forms/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/inspection-times',
    name: 'Inspection Times',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/market-updates',
    name: 'Market Updates',
    component: () => import('../views/MarketUpdates.vue'),
  },
  {
    path: '/your-suburb-report',
    name: 'Your Suburb Report',
    component: () => import('../views/YourSuburbReport.vue'),
  },
  {
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Make an Offer',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    meta: {
      hide: true,
    },
    component: () => import('../views/Unsubscribe.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    console.log(to);

    if (to.hash) {
      Vue.nextTick(() => {
        setTimeout(() => {
          const elem = document.getElementById(to.hash.substring(1));

          if (elem) {
            window.scrollTo({
              top: ScrollUtils.GetCoords(elem).top - 100,
              left: 0,
              behavior: 'smooth',
            });
          }
        }, 100);
      });

      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    }

    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
