






import { Component, Vue, Prop } from 'vue-property-decorator';
import ScrollUtils from '../../app_code/ScrollUtils';

@Component
export default class NavigationLink extends Vue {
  @Prop()
  readonly to!: string;

  @Prop()
  readonly external!: boolean;

  @Prop()
  readonly isAnchor!: boolean;

  handleClicked(e: Event) {
    e.preventDefault();

    if (this.isAnchor) {
      console.log(this.to.substr(2));
      const element = document.getElementById(this.to.substr(2));
      if (element) {
        window.scrollTo({
          top: ScrollUtils.GetCoords(element).top - 100,
          left: 0,
          behavior: 'smooth',
        });
      }
    } else if (this.external) {
      window.open(this.to, '_blank');
    } else if (this.$route.path !== this.to) {
      this.$router.push(this.to);
    }
  }
}
